// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import Img from 'gatsby-image'

// import GoogleMapWithMarker from '@bodhi-project/components/lib/map/GoogleMapWithMarker'

// import Division from '@bodhi-project/components/lib/Division'
// import '@bodhi-project/antrd/lib/just-futura/3.19.3/row/style/css'
// import '@bodhi-project/antrd/lib/just-futura/3.19.3/col/style/css'

// import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'
import Video from '@bodhi-project/components/lib/Video'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import K12SchoolPageWrapper from '../components/wrappers/K12SchoolPageWrapper'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'K12 Schools | Learning Wings Education System',
  nakedPageSlug: 'about',
  pageAbstract:
    'Learning Wings Education Systems has K1-12 and Early Education schools spread over North India. At our schools the goal is to nurture the dreams of tender hearts. Every child is equipped with the confidence to pursue the dreams and realize them. In this world of turmoil and unrest they learn to pave their own path to success.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <K12SchoolPageWrapper className="page" seoData={seoData} {...props}>
    <h1 class="shadow" data-shadow="K12 Schools">
      K12 Schools
    </h1>
    <div style={{ maxWidth: '50rem' }}>
      <Video
        url="https://www.youtube.com/watch?v=E9FlGtT61eA"
        className="mask-p"
      />
      <p>
        Learning Wings Education Systems has K1-12 and Early Education schools
        spread over North India. At our schools the goal is to nurture the
        dreams of tender hearts. Every child is equipped with the confidence to
        pursue the dreams and realize them. In this world of turmoil and unrest
        they learn to pave their own path to success.
      </p>
    </div>
  </K12SchoolPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
